<template>
    <div>
        <br>
        <button class="button is-link is-light is-pulled-right is-small" v-on:click="add()">
            Cadastrar
        </button>  

        <form>
            <div class="field is-horizontal">
                <div class="field-body">
                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select v-model="cmbPerfil">
                                    <option value="0" disabled>Selecione um perfil</option>
                                    <option v-for="usuarioPerfil in usuariosPerfil" :key="usuarioPerfil.id" :value="usuarioPerfil.perfil">
                                        {{usuarioPerfil.perfil.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div>  

                    <div class="field is-narrow">
                        <div class="control">
                            <div class="select is-fullwidth">
                                <select v-model="cmbTipoPeriodo" @change="selectTipoPeriodo()">
                                    <option v-for="td in tiposPeriodo" :key="td.id" :value="td.id">
                                        {{td.descricao}}
                                    </option>
                                </select>
                            </div>    
                        </div>
                    </div> 
                    
                    <div class="field is-narrow">

                        <div class="control">
                            <div class="field is-fullwidth">
                                <input class="input" type="text" placeholder="Se desejar, informe um ano" v-model="nrAno">
                            </div>
                        </div>

                    </div>
                    
                    <div class="field">                    
                        <button type='submit' class="button is-link" @click.stop.prevent="filterRecords">Filtrar</button>
                    </div>    
                </div>
            </div>
        </form>  

        <hr>

        <div class="modal" v-bind:class="{ 'is-active': isProcessing }">
            <div class="modal-background"></div>
            <div class="modal-content">
                <progress class="progress is-large is-primary" max="100">50%</progress>
            </div>
        </div>        

        <div class="modal" v-bind:class="{ 'is-active': isShowModal }">
            <div class="modal-background" v-on:click="isShowModal = false"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                <p class="modal-card-title">Cadastrar</p>
                <button class="delete" aria-label="close" v-on:click="isShowModal = false"></button>
                </header>
                <section class="modal-card-body">
                    <form>
                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Período</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                            <select v-model="periodoPerfil.periodo" :disabled="periodoPerfil.estaFechado">
                                                <option v-for="periodo in periodos" :key="periodo.id" :value="periodo">
                                                    {{periodo.descricao}}
                                                </option>
                                            </select>
                                        </div>    
                                    </div>
                                </div>  
                                <div class="field">
                                    <label class="checkbox">
                                        <input type="checkbox" v-model="periodoPerfil.estaFechado"/>
                                        Fechado
                                    </label>                        
                                </div>    
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Versão</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field">
                                    <div class="control">
                                        <div class="select is-fullwidth">
                                        <select v-model="periodoPerfil.versao_plano_contas" disabled>
                                            <option v-for="versao_plano_contas in versoesPlanosContas" :key="versao_plano_contas.id" :value="versao_plano_contas">
                                                {{versao_plano_contas.descricao}}
                                            </option>
                                        </select>
                                        </div>    
                                    </div>
                                </div>  
                            </div>
                        </div>

                        <div class="field is-horizontal">
                            <div class="field-label is-normal">
                                <label class="label"><small>Apresentação</small></label>
                            </div>                            
                            <div class="field-body">
                                <div class="field is-normal">
                                    <textarea class="textarea" placeholder="Descreva um texto de apresentação" v-model="periodoPerfil.mensagem" :disabled="periodoPerfil.estaFechado">
                                    </textarea>
                                </div>
                            </div>
                        </div>

                        <div class="file has-name is-fullwidth" v-if="!periodoPerfil.estaFechado">
                            <label class="file-label">
                                <input class="file-input" type="file" ref="file" @change="uploadFile">
                                <span class="file-cta">
                                <span class="file-icon">
                                    <i class="fas fa-upload"></i>
                                </span>
                                <span class="file-label">
                                    Selecione um arquivo
                                </span>
                                </span>
                                <span class="file-name">
                                    {{ arquivoCSVName }}
                                </span>
                            </label>
                        </div>
                    </form>  
                </section>
                <footer class="modal-card-foot">
                <button class="button is-success" @click="save()">Salvar</button>
                <button class="button" v-on:click="isShowModal = false">Cancelar</button>
                </footer>
            </div>
        </div>        

        <div class="columns" v-show="this.showInfo">
            <div class="column is-two-fifths">
                <div class="card">
                    <header class="panel-heading has-background-link-dark has-text-light">
                        <nav class="level">
                            <div class="level-left">
                                <p class="has-text-white-bis">
                                    <small>Períodos</small>
                                </p>
                            </div>    
                        </nav>    
                    </header>
                    <div class="card-content">
                        <div class="table-container">                
                            <table class="table is-striped is-narrow is-hoverable is-fullwidth">
                                <thead>
                                    <tr>
                                        <th><small>Período</small></th>
                                        <th colspan="7" class="has-text-centered"><small>Ações</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(periodoPerfil, index) in periodosPerfis" :key="periodoPerfil.id">
                                        <td><small>{{periodoPerfil.periodo.descricao}}</small></td>
                                        <td class="has-text-centered" style="width:15px"><small><button class="button is-ghost is-small is-inverted is-link has-tooltip-left" data-tooltip="Editar período" @click="edit(index)"><span class="fas fa-edit"></span></button></small></td>
                                        <td class="has-text-centered" style="width:15px"><small><button class="button is-ghost is-small is-inverted is-link has-tooltip-left" data-tooltip="Excluir período" @click="erase(index)" :disabled="periodoPerfil.estaFechado"><span class="fas fa-trash-alt"></span></button></small></td>
                                        <td class="has-text-centered" style="width:15px"><small><button class="button is-ghost is-small is-inverted is-link has-tooltip-left" data-tooltip="Importar balancete" @click="upload(index)" :disabled="periodoPerfil.estaFechado"><span class="fas fa-upload"></span></button></small></td>
                                        <td class="has-text-centered" style="width:15px"><small><button class="button is-ghost is-small is-inverted is-link has-tooltip-left" data-tooltip="Processar demonstrações" @click="processar(index)" :disabled="periodoPerfil.estaFechado"><span class="fas fa-play"></span></button></small></td>
                                        <td class="has-text-centered" style="width:15px"><small><button class="button is-ghost is-small is-inverted is-link has-tooltip-left" data-tooltip="Gerar demonstrações" @click="gerarPDF(index)"><span class="fas fa-file-pdf"></span></button></small></td>
                                        <td class="has-text-centered" style="width:15px"><small><button class="button is-ghost is-small is-inverted is-link has-tooltip-left" data-tooltip="Enviar planilha" @click="gerarExcel(index)"><span class="fas fa-file-excel"></span></button></small></td>
                                        <td class="has-text-centered" style="width:15px"><small><button class="button is-ghost is-small is-inverted is-link has-tooltip-left" data-tooltip="Ocorrências" @click="callLogUpload(index)"><span class="fas fa-th-list"></span></button></small></td>
                                    </tr>
                                </tbody>    
                            </table>
                        </div> 
                        <nav class="pagination is-rounded is-small" role="navigation" aria-label="pagination">
                            <a class="pagination-previous button is-link" @click="previousPage()" :disabled="this.pagePeriodoPerfil == 1 || this.pagePeriodoPerfil == null">Anterior</a>
                            <a class="pagination-next button is-link" @click="nextPage()" :disabled="this.pagePeriodoPerfil == this.numpagesPeriodoPerfil">Próxima</a>                
                        </nav>
                    </div>  
                </div>         
                <br>
            </div>

            <div class="column">
                
                <div class="column" v-show="this.mostraLogUpload">
                    <div class="card">
                        <header class="panel-heading has-background-grey">
                            <nav class="level">
                                <div class="level-left">
                                    <p class="has-text-white-bis">
                                        <small>Log da Importação</small>
                                    </p>
                                </div>    
                                <div class="level-right">
                                    <small><a v-on:click="mostraLogUpload = false"><span class="fa fa-times has-text-black has-text-white-bis"></span></a></small>
                                </div>
                            </nav>    
                        </header>

                        <div class="card-content">
                            <div class="table-container">                
                                <table class="table is-narrow is-fullwidthtable is-bordered is-striped is-narrow is-hoverable is-fullwidth">
                                    <thead>
                                        <tr>
                                            <th class="has-text-left"><small>Nº Linha</small></th>
                                            <th class="has-text-left"><small>Ocorrência</small></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="log in periodoPerfilLogsUpload" :key="log.id">
                                            <td class="has-text-left"><small>{{log.linha}}</small></td>
                                            <td class="has-text-left"><small>{{log.erro}}</small></td>
                                        </tr>
                                    </tbody>    
                                </table>
                            </div>    

                            <nav class="pagination is-rounded is-small" role="navigation" aria-label="pagination">
                                <a class="pagination-previous button is-link" @click="previousPage()" :disabled="this.page == 1 || this.page == null">Anterior</a>
                                <a class="pagination-next button is-link" @click="nextPage()" :disabled="this.page == this.numpages">Próxima</a>                
                            </nav>

                            <br>
                        </div>
                    </div>
                </div>
            </div>

        </div>   

    </div>        

</template>

<script>
    // import _ from 'lodash'
    import {http} from '../../services/config'

    export default {
        name: "PeriodoPerfil",

        data(){
            return{
                periodoPerfil: {
                    id: null,
                    perfil: null,
                    periodo: null,
                    versao_plano_contas: null,
                    fileUpload: null,
                    estaFechado: false,
                    ultimo_usuario_upload: null,
                    data_ultimo_upload: null,
                    mensagem: '',
                    index: null
                },
                periodoPerfilSelecionado: null,
                periodosPerfis: [],
                usuariosPerfil: [],
                periodoPerfilLogsUpload: [],
                periodos: [],
                versoesPlanosContas: [],
                tiposPeriodo: [],
                arquivoCSVName: null,
                previous: null,
                next: null,
                page: null,
                numpages: null,

                previousPeriodoPerfil: null,
                nextPeriodoPerfil: null,
                pagePeriodoPerfil: null,
                numpagesPeriodoPerfil: null,

                isShowModal: false,
                isProcessing: false,
                mostraLogUpload: false,
                cmbPerfil: "0",
                cmbTipoPeriodo: null,
                nrAno: null,
                showInfo: false
            }
        },

        methods: {
            add: function() {
                if (this.cmbPerfil == null) {
                    alert('Perfil não selecionado!')
                    return
                } else if (this.cmbPerfil.versao_plano_contas == null) {
                    alert('Perfil não tem uma versão associada! Inclusão de período não permitida')
                    return
                }
                if (this.cmbTipoPeriodo == null) {
                    alert('Tipo de período não selecionado!')
                    return
                }
                http.get('versoesPlanoContas/listar', {params: {
                    idPerfil: this.cmbPerfil.id
                }}).then(response =>{
                    this.versoesPlanosContas = response.data.results
                })
                this.clearFields()
                this.isShowModal = true              
            },

            edit: function(index){
                this.periodoPerfil.id = this.periodosPerfis[index].id
                this.periodoPerfil.perfil = this.periodosPerfis[index].perfil
                this.periodoPerfil.periodo = this.periodosPerfis[index].periodo
                this.periodoPerfil.versao_plano_contas = this.periodosPerfis[index].versao_plano_contas
                this.periodoPerfil.fileUpload = null
                this.periodoPerfil.estaFechado = this.periodosPerfis[index].estaFechado
                this.periodoPerfil.ultimo_usuario_upload = this.periodosPerfis[index].ultimo_usuario_upload
                this.periodoPerfil.data_ultimo_upload = this.periodosPerfis[index].data_ultimo_upload
                this.periodoPerfil.index = index
                this.isShowModal = true              
            },

            clearFields: function() {
                this.periodoPerfil = {
                    id: null,
                    perfil: null,
                    periodo: null,
                    versao_plano_contas: this.cmbPerfil.versao_plano_contas,
                    fileUpload: null,
                    estaFechado: false,
                    ultimo_usuario_upload: null,
                    data_ultimo_upload: null,
                    mensagem: '',
                    index: null }            
            },

            uploadFile: function() {
                this.periodoPerfil.fileUpload = this.$refs.file.files[0]
                this.arquivoCSVName = this.$refs.file.files[0].name
            },   

            erase: function(index){
                if (window.confirm('Confirma exclusão?')) {
                    http.delete('/periodoPerfil/excluir/'+this.periodosPerfis[index].id).then(response =>{
                            this.periodosPerfis.splice(index, 1)
                            if (response.status == 204) {
                                alert('Registro excluído!')
                            }
                        }).catch(e => {
                            console.log(e);
                        })
                }
            },

            save: function(){
                var data

                if (this.periodoPerfil.periodo == null) {
                    alert('Período não informado!')
                    return
                }
                if (this.periodoPerfil.versao_plano_contas == null) {
                    alert('Versão do plano de contas não informado!')
                    return
                }

                data = { id: this.periodoPerfil.id,
                    perfil: this.cmbPerfil.id,
                    periodo: this.periodoPerfil.periodo.id,
                    versao_plano_contas: this.periodoPerfil.versao_plano_contas.id,
                    estaFechado: this.periodoPerfil.estaFechado,
                    fileUpload: this.periodoPerfil.fileUpload,
                    mensagem: this.periodoPerfil.mensagem
                }

                let formData = new FormData()

                formData.append('id', data.id)
                formData.append('perfil', data.perfil)
                formData.append('periodo', data.periodo)
                formData.append('versao_plano_contas', data.versao_plano_contas)
                formData.append('estaFechado', data.estaFechado)
                formData.append('fileUpload', data.fileUpload)
                formData.append('mensagem', data.mensagem)

                if (this.periodoPerfil.id == null) {
                    http.post('/periodoPerfil/adicionar', formData, {
                        headers: {
                                "Content-Type": "multipart/form-data",
                        }}).then(response =>{
                        if (response.data.id != undefined && response.data.id != '') {
                            this.periodoPerfil.id = response.data.id
                            this.periodosPerfis.push(this.periodoPerfil)
                            this.showInfo = true
                            this.clearFields()
                        } else {
                            alert(response.request.responseText)
                        }
                    }).catch(e => {
                        console.log(e);
                    })

                } else {
                    http.put('/periodoPerfil/editar/'+this.periodoPerfil.id, formData, {
                        headers: {
                                "Content-Type": "multipart/form-data",
                        }}).then(response =>{
                        this.periodosPerfis[this.periodoPerfil.index].perfil = this.periodoPerfil.perfil
                        this.periodosPerfis[this.periodoPerfil.index].periodo = this.periodoPerfil.periodo
                        this.periodosPerfis[this.periodoPerfil.index].estaFechado = response.data.estaFechado
                        this.periodosPerfis[this.periodoPerfil.index].mensagem = response.data.mensagem
                        this.periodosPerfis[this.periodoPerfil.index].versao_plano_contas = this.periodoPerfil.versao_plano_contas
                    }).catch(e => {
                        console.log(e);
                    })
                }

            },

            nextPage: function(){
                if (this.mostraLogUpload) {

                    if (this.page != this.numpages) {
                        http.get(this.next, {params: {
                            idPeriodoPerfil: this.periodoPerfilSelecionado.id,
                        }}).then(response =>{
                            this.periodoPerfilLogsUpload = response.data.results
                            this.previous = response.data.previous
                            this.next = response.data.next
                            this.page = response.data.page
                            this.numpages = response.data.numpages
                        })
                    }

                } else {

                    if (this.pagePeriodoPerfil != this.numpagesPeriodoPerfil) {
                        http.get(this.nextPeriodoPerfil, {params: {
                            idPerfil: this.cmbPerfil.id,
                            idTipoPeriodo: this.cmbTipoPeriodo,
                            ano: this.nrAno
                        }}).then(response =>{
                            this.periodosPerfis = response.data.results
                            this.previousPeriodoPerfil = response.data.previous
                            this.nextPeriodoPerfil = response.data.next
                            this.pagePeriodoPerfil = response.data.page
                            this.numpagesPeriodoPerfil = response.data.numpages
                        })
                    }

                }
            },
            previousPage: function(){
                if (this.mostraLogUpload) {

                    if (this.page != 1) {
                        http.get(this.previous, {params: {
                            idPeriodoPerfil: this.periodoPerfilSelecionado.id,
                        }}).then(response =>{
                            this.periodoPerfilLogsUpload = response.data.results
                            this.previous = response.data.previous
                            this.next = response.data.next
                            this.page = response.data.page
                            this.numpages = response.data.numpages
                        })
                    }

                } else {
                    if (this.pagePeriodoPerfil != 1) {
                        http.get(this.previousPeriodoPerfil, {params: {
                            idPerfil: this.cmbPerfil.id,
                            idTipoPeriodo: this.cmbTipoPeriodo,
                            ano: this.nrAno
                        }}).then(response =>{
                            this.periodosPerfis = response.data.results
                            this.previousPeriodoPerfil = response.data.previous
                            this.nextPeriodoPerfil = response.data.next
                            this.pagePeriodoPerfil = response.data.page
                            this.numpagesPeriodoPerfil = response.data.numpages
                        })
                    }

                }

            },
            filterRecords: function(){
                if (this.cmbPerfil == "0") {
                    alert('Perfil não selecionado!')
                    return
                }
                if (this.cmbTipoPeriodo == null) {
                    alert('Tipo de período não informado!')
                    return
                }
                
                http.get('periodoPerfil/listar/', {params: {
                    idPerfil: this.cmbPerfil.id,
                    idTipoPeriodo: this.cmbTipoPeriodo,
                    ano: this.nrAno
                }}).then(response => {
                    this.periodosPerfis = response.data.results
                    this.previousPeriodoPerfil = response.data.previous
                    this.nextPeriodoPerfil = response.data.next
                    this.pagePeriodoPerfil = response.data.page
                    this.numpagesPeriodoPerfil = response.data.numpages

                    if (this.periodosPerfis.length != 0) {
                        this.showInfo = true
                    } else {
                        this.showInfo = false
                        alert('Não há dados associados aos parâmetros informados!')
                    }
                })

                http.get('versoesPlanoContas/listar', {params: {
                    idPerfil: this.cmbPerfil.id
                }}).then(response =>{
                    this.versoesPlanosContas = response.data.results
                })
            },

            upload: function(index){
                if (window.confirm('Confirma importação?')) {
                    this.isProcessing = true
                    http.put('/periodoPerfil/importarArquivo/'+this.periodosPerfis[index].id).then(response =>{
                        if (response.status == 200) {
                            alert('Importação Concluída!')
                        } else if (response.status == 204) {
                            alert('Arquivo não carregado! Possíveis motivos: Não informado ou não são do tipo CSV ou XLSX.')
                        }
                        this.isProcessing = false
                    }).catch(e => {
                        this.isProcessing = false
                        console.log(e);
                    })
                }
            },

            processar: function(index) {
                if (window.confirm('Confirma o processamento das demonstrações e indicadores?')) {
                    this.isProcessing = true
                    http.get('/periodoPerfil/processarDemonstracoes/', {params: {
                            idPeriodoPerfil: this.periodosPerfis[index].id
                        }}).then(response =>{
                        if (response.status == 204) {
                            this.isProcessing = false
                            alert('Processamento concluído!')
                        }
                    }).catch(e => {
                        this.isProcessing = false
                        console.log(e);
                    })
                }
            },

            gerarPDF: function(index) {
                var cFile 

                cFile = 'Dados_Economico_Financeiros_' + this.cmbPerfil.id.toString() + '_' + this.cmbPerfil.descricao + '_' + this.periodosPerfis[index].periodo.descricao + '_' + this.periodosPerfis[index].id + '.pdf'

                if (window.confirm('Confirma publicação dos resultados?')) {
                        this.isProcessing = true
                        http.get('/periodoPerfil/gerarPDF/', {params: {
                            idPeriodoPerfil: this.periodosPerfis[index].id,
                            nomeArquivo: cFile
                        }}).then(response =>{
                        if (response.status == 200) {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            link.setAttribute('download', cFile /*'hello.pdf'*/);
                            document.body.appendChild(link);
                            link.click();                            
                            this.isProcessing = false
                        }
                    }).catch(e => {
                        console.log(e);
                    })
                }
            },

            gerarExcel: function(index) {
                var cFile 

                cFile = 'Dados_Economico_Financeiros_' + this.cmbPerfil.id.toString() + '_' + this.cmbPerfil.descricao + '_' + this.periodosPerfis[index].periodo.descricao + '_' + this.periodosPerfis[index].id + '.xlsx'

                if (window.confirm('Confirma geração da planilha?')) {
                    this.isProcessing = true
                    http.get('/periodoPerfil/gerarExcel/', {params: {
                            idPeriodoPerfil: this.periodosPerfis[index].id,
                            nomeArquivo: cFile}}).then(response =>{
                        if (response.status == 204) {
                            this.isProcessing = false
                            alert('Planilha gerada e enviada por e-mail!!')
                        } else {
                            this.isProcessing = false
                        }
                    }).catch(e => {
                        console.log(e);
                    })
                }
            },


            callLogUpload: function(index) {
                this.mostraLogUpload = true
                this.periodoPerfilSelecionado = this.periodosPerfis[index]
                http.get('periodoPerfil/listarLogUpload/', {params: {
                    idPeriodoPerfil: this.periodosPerfis[index].id,
                }}).then(response => {
                    this.periodoPerfilLogsUpload = response.data.results
                    this.previous = response.data.previous
                    this.next = response.data.next
                    this.page = response.data.page
                    this.numpages = response.data.numpages
                })
            },

            selectTipoPeriodo: function() {
                http.get('periodosAccounting/listar', {params: {
                    idTipoPeriodo: this.cmbTipoPeriodo
                }}).then(response => {
                    this.periodos = response.data.results
                    if (this.periodos.length == 0) {
                        alert('Não há períodos associados ao tipo selecionado!')
                    }
                })
            },

        },

        mounted: function() {
        },
        
        created: function() {
            http.get('compartilharPerfis/listarPerfis').then(response => {
                    this.usuariosPerfil = response.data.results
            })

            http.get('tiposPeriodo/listar').then(response => {
                this.tiposPeriodo = response.data.results
            })

        }

    }
</script>

<style scoped>
</style>